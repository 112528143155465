@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:300&display=swap");
html,
body {
	font-family: "IBM Plex Mono", monospace;
	font-size: 62.5%;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}
#root {
	height: 100%;
}
